var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "misc-wrapper" },
    [
      _c("b-link", { staticClass: "brand-logo" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/logo/echope.png"),
            width: "150px",
          },
        }),
      ]),
      _c("div", { staticClass: "misc-inner p-2 p-sm-3" }, [
        _c(
          "div",
          { staticClass: "w-100 text-center" },
          [
            _c("h2", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("Página não encontrada")) + " 🕵🏻‍♀️ "),
            ]),
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("Oops!")) +
                  " 😖 " +
                  _vm._s(_vm.$t("A URL solicitada não foi encontrada")) +
                  " "
              ),
            ]),
            _c(
              "b-button",
              {
                staticClass: "mb-2 btn-sm-block",
                attrs: { variant: "primary" },
                on: { click: _vm.goBack },
              },
              [_vm._v(" " + _vm._s(_vm.$t("Voltar")) + " ")]
            ),
            _c("br"),
            _c("b-img", {
              attrs: { fluid: "", src: _vm.imgUrl, alt: "Error page" },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }